import React from "react";

function Card(props) {
  return (
    <div className="card">
      <img src={props.image} alt="food" />
      <h2 className="title">{props.title}</h2>
      <div className="description">
        <h4>{props.ingredient}</h4>
        <p>{props.ingredients}</p>
      </div>
      <div className="description-2">
        <h4>{props.direction}</h4>
        <p>{props.directions}</p>
      </div>
    </div>
  );
}

export default Card;
