import React from "react";
import PayPalPayment from "./PayPalPayment";

function CheckoutPage() {
  return (
    <div>
      <h2 className="amount-title">For only 10$</h2>
      <PayPalPayment />
    </div>
  );
}

export default CheckoutPage;
