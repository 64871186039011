import React from "react";

function Footer() {
  return (
    <div className="contact-us">
      <div className="contact-title">
        <h2>Contact Us</h2>
        <br />
        <p className="contact-info">
          For any queries or inquiries. Email: amosfinds@gmail.com{" "}
        </p>
      </div>
    </div>
  );
}

export default Footer;
