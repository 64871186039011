import React from "react";

function Navbar() {
  return (
    <div className="navbar">
      <h1 className="logo">HIDDEN MENU</h1>
      {/* <p className="login-nav">Contact Us</p> */}
      {/* <p className="signup-nav">Sign Up</p> */}
    </div>
  );
}

export default Navbar;
