import React from "react";
import Card from "./Card";
import { Link } from "react-router-dom";

function Recipe() {
  return (
    <div>
      <div className="sales-heading">
        {/* <h2>
          Lost as to what meals will give you enough calories to gain weight?
          <br />
          Are you a hardgainer looking to bulk up but your wallet can't afford
          to have steak every day?
          <br /> If you never want to have that feeling of frustration again of
          constantly tracking calories you can try our Hidden Menu!
        </h2> */}
        <h2>
          A convenient collection of affordable high-calorie meal recipes
          ranging from 800-1000 calories.
          <br /> You can implement these sample meals in your diet today to hit
          that calorie goal.
        </h2>
      </div>
      <div className="first-row">
        <Card
          image="images/shake.jpg"
          title="Protein Shake"
          ingredient="Ingredients:"
          ingredients="1 Banana, Half a cup of Ice cream, 1 cup of Oats, 2 Tablespoons of Peanut butter, 1 Tablespoon of Honey as well as  2 cups of Milk."
          direction="Directions:"
          directions="Add all the ingredients in a blender and blend it all till smooth and you have an easy 1000 calorie drink."
        />
        <Card
          image="images/eggburger.jpg"
          title="Beef and Egg Burger"
          ingredient="Ingredients:"
          ingredients="Three eggs, Two pairs of burger buns, Two beef patties and any assortment of condiments."
          direction="Directions:"
          directions="Pan-fry scrambled(or fried) eggs and the beef patties(Can also be grilled or oven-cooked), then place them in the bun with any type of sauce. 800-900 calories. "
        />
        <Card
          image="images/cheesemac.jpg"
          title="Macaroni and Cheese"
          ingredient="Ingredients:"
          ingredients="Macaroni, Cheddar cheese, or any other alternative cheese of your choice, as well as Milk, Flour and Butter."
          direction="Directions:"
          directions="First, start off with 100 grams of macaroni in a pot of salted boiling water and cook it all the way through(8-10 minutes).
          Heat two tablespoons of butter in a pot, add a tablespoon of flour. Mix, then add a cup of milk and wait until it thickens. Then add a cup of shredded cheese.
          Mix until cheese is melted.
                      Pour the cheese sauce over your cooked macaroni and your meal will be complete. 1100 calories."
        />
        <Card
          image="images/pastapesto.jpg"
          title="Macaroni and Pesto"
          ingredient="Ingredients:"
          ingredients="200G of Macaroni and 190G+ of Basil Pesto. "
          direction="Directions:"
          directions="First, start off with 200 grams of macaroni in a pot of salted boiling water and cook it all the way through(8-10 minutes).
          Add the Basil Pesto and stir it in. 1200 calories."
        />
      </div>
      <div className="sales-purchase">
        <h2>
          If you want more recipes, you can try out 5 more meals to put in your
          weekly schedule.
        </h2>
        <br />
        <Link to="/checkoutpage">
          <button>Get Started</button>
        </Link>
      </div>
      {/* <div className="second-row">
        <Card
          image=""
          title=""
          ingredient=""
          ingredients=""
          direction=""
          directions=""
        />
        <Card
          image=""
          title=""
          ingredient=""
          ingredients=""
          direction=""
          directions=""
        />
        <Card
          image=""
          title=""
          ingredient=""
          ingredients=""
          direction=""
          directions=""
        />
        <Card
          image=""
          title=""
          ingredient=""
          ingredients=""
          direction=""
          directions=""
        />
      </div>
      <div className="third-row">
        <Card
          image=""
          title=""
          ingredient=""
          ingredients=""
          direction=""
          directions=""
        />
        <Card
          image=""
          title=""
          ingredient=""
          ingredients=""
          direction=""
          directions=""
        />
        <Card
          image=""
          title=""
          ingredient=""
          ingredients=""
          direction=""
          directions=""
        />
        <Card
          image=""
          title=""
          ingredient=""
          ingredients=""
          direction=""
          directions=""
        />
      </div> */}
    </div>
  );
}

export default Recipe;
