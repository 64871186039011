import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import CheckoutPage from "./CheckoutPage";
import Home from "./Home";
import Footer from "./Footer";
import Success from "./Success";

function App() {
  const initialOptions = {
    clientId:
      "AYeJITAPLjK3YUEVG128T7vsNJBb4BHyH9HZ8Woy_tfPh6rSPJYm6vS_euOoSWINpNhypEYIGgj40IAp",
    currency: "USD",
    intent: "capture",
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <div>
        <Router>
          <Home />
          <Routes>
            <Route path="checkoutpage" element={<CheckoutPage />} />
            <Route path="success" element={<Success />} />
          </Routes>
          <Footer />
        </Router>
      </div>
    </PayPalScriptProvider>
  );
}

export default App;
