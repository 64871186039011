import React from "react";
import pdf from "./BulkRecipe.pdf";

const Success = (props) => {
  return (
    <div className="success-page">
      <h1>Your payment was successful!</h1>
      <a href={pdf} download="BulkRecipe.pdf">
        <button className="download-button">Download Recipes</button>
      </a>
    </div>
  );
};

export default Success;
