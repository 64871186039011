import React from "react";
import Navbar from "./Navbar";
import Recipe from "./Recipe";

function Home() {
  return (
    <div>
      <Navbar />
      <Recipe />
    </div>
  );
}

export default Home;
